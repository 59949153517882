exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-pages-workshops-index-js": () => import("./../../../src/pages/workshops/index.js" /* webpackChunkName: "component---src-pages-workshops-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-post-presentation-index-js": () => import("./../../../src/templates/post-presentation/index.js" /* webpackChunkName: "component---src-templates-post-presentation-index-js" */),
  "component---src-templates-workshop-index-js": () => import("./../../../src/templates/workshop/index.js" /* webpackChunkName: "component---src-templates-workshop-index-js" */),
  "component---src-templates-workshop-presentation-index-js": () => import("./../../../src/templates/workshop-presentation/index.js" /* webpackChunkName: "component---src-templates-workshop-presentation-index-js" */)
}

